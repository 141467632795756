<template>
  <div class="trade_stat_settle_container">
    <div class="plat-wrap-box">
      <a v-for="platform in platforms" :key="platform.code"
         :class="{active:search.platform_code===platform.code}"
         class="account-plat-item lf " @click="search.platform_code=platform.code">
        <img :src="platform.logo_url" alt="" style="width: 25px;height: 25px;" />
        <span class="account-plat-item-name lf">{{ platform.name }}</span>
      </a>
    </div>
    <el-form inline :model="search" class="trade_stat_settle_container__search">
      <el-form-item>
        <div class="ml-tabs-box" style="margin-right: 10px;">
          <div :class="`ml-tab ${search.by_key==='product'?'ml-tab_active':''}`" @click="search.by_key='product'">
            按产品
          </div>
          <div :class="`ml-tab ${search.by_key==='product_and_artist'?'ml-tab_active':''}`"
               @click="search.by_key='product_and_artist'">
            按产品+红人
          </div>
          <div class="ml-tab_active-bg" :style="`${search.by_key==='product'?'left: 2px;':'right: 2px;'}`" />
        </div>
        <div class="ml-tabs-box">
          <div :class="`ml-tab ${search.by==='year'?'ml-tab_active':''}`" @click="search.by='year'">
            按年
          </div>
          <div :class="`ml-tab ${search.by==='month'?'ml-tab_active':''}`" @click="search.by='month'">
            按月
          </div>
          <div class="ml-tab_active-bg" :style="`${search.by==='year'?'left: 2px;':'right: 2px;'}`" />
        </div>
        <el-date-picker
          :key="search.by" v-model="search.date" :type="search.by" value-format="yyyy-MM" size="mini"
          style="width: 128px;margin-left: 10px;"
          :clearable="false"
          @change='handleSearch'
        />
      </el-form-item>
      <el-form-item>
        <el-input v-model='search.product_title' clearable placeholder='搜索产品关键词' @change='handleSearch' />
      </el-form-item>
      <el-form-item v-if="search.by_key==='product_and_artist'">
        <artist-search :artist-id.sync="search.artist_id" @handleSelect='handleSearch' />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="handleSearch">查询</el-button>
        <export-by-page module="TRADE_PRODUCT_STAT" :search="search" icon="" :columns="export_fields"
                        :number-columns="money_fields_names" />
      </el-form-item>
      <el-form-item style="float: right;">
        <ml-button icon="el-icon-refresh" type="text" text="初始化数据" @click.native="handleInitStats" />
      </el-form-item>
    </el-form>
    <div style="height: calc(100% - 52px);">
      <ml-table :key="search.platform_code+search.by_key+search.by+search.date" ref="TABLE" hand-request
                show-summary :search.sync="search" :api="apiList"
                :summary-method="getSummaries" @response="callbackResponse">
        <template slot="columns">
          <el-table-column type='index' label='排名' min-width='80' align='center' fixed>
            <template v-slot='{$index}'>
              <img
                v-if='(pages.current_page===1)&&$index<3'
                :src='require(`@/assets/imgs/rank-${$index+1}.png`)'
                alt=''
                style='width: 16px;'
              >
              <div v-else style='height: 24px;line-height: 24px;'>
                {{ ((pages.current_page - 1) * pages.page_size + $index) > 8 ? '' : '0' }}{{
                  (pages.current_page - 1) * pages.page_size + $index + 1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="product_title" label="产品名称" min-width="300" show-overflow-tooltip fixed />
          <el-table-column v-if="search.by_key==='product_and_artist'" prop="artist_nickname" label="红人昵称"
                           show-overflow-tooltip fixed />
          <el-table-column v-for="field in money_fields" :prop="field.prop" :key="field.prop" :label="field.label"
                           :min-width="field.label.length>=5?200:100"
                           sortable='custom'
                           align="right">
            <template v-slot="{row}">
              <b> {{ row[field.prop]|numberFormat }}</b>
            </template>
          </el-table-column>
        </template>
        <template slot="footer">
          <a href="/trade" target="_blank" style="color: #AAAAAA"><i class="el-icon-warning" />&nbsp;数据来源：「支付时间」的平台数据</a>
        </template>
      </ml-table>
    </div>
  </div>
</template>
<script>
import MlTable from '@/components/MlTable/index.vue'
import MlButton from '@/components/MlButton/index.vue'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import {tradeProductStatInit, tradeProductStatList} from '../../../api/trade_stat'
import {calcMonths} from '@/utils'
import moment from 'moment/moment'
import ExportByPage from '@/components/export/ByPage/index.vue'
import {numberFormat} from "@/utils/utils";

let platforms = localStorage.getItem('platforms')
platforms = platforms ? JSON.parse(platforms) : []
const genKey = function() {
  return ((new Date().getTime() / 1000) | 0).toString(36)
}
export default {
  components: { ExportByPage, MlButton, ArtistSearch, MlTable },
  data() {
    return {
      platforms,
      key: genKey(),
      pages: {
        current_page: 1,
        page_size: 20
      },
      money_fields: [],
      search: {
        by: 'year',
        by_key: 'product',
        artist_id: null,
        product_title: null,
        date: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        platform_code: 'douyin'
      }
    }
  },
  computed: {
    export_fields() {
      const obj = {
        '排名': 'index',
        '产品名称': 'product_title'
      }
      if (this.search.by_key === 'product_and_artist') {
        obj['红人昵称'] = 'artist_nickname'
      }
      const tmp = {}
      this.money_fields.map(_ => {
        tmp[_.label] = _.prop
      })
      return { ...obj, ...tmp }
    },
    money_fields_names() {
      return this.money_fields.map(_ => {
        return _.label
      })
    }
  },
  watch: {
    'search.by': {
      handler() {
        this.search.months = calcMonths(this.search.by, this.search.date)
        this.handleSearch()
      },
      immediate: true
    },
    'search.platform_code': {
      handler() {
        this.search.months = calcMonths(this.search.by, this.search.date)
        this.handleSearch()
      }
    },
    'search.date': {
      handler() {
        this.search.months = calcMonths(this.search.by, this.search.date)
        this.handleSearch()
      }
    },
    'search.by_key': {
      handler() {
        this.handleSearch()
      }
    }
  },
  methods: {
    apiList: tradeProductStatList,
    handleInitStats: async function() {
      await tradeProductStatInit({ months: this.search.months })
      this.$message.success('初始化【所有平台】商品汇总任务发布，请稍后查看数据。')
    },
    handleSearch: function() {
      this.$nextTick(() => {
        this.$refs.TABLE.handleSearch()
      })
    },
    callbackResponse: function({ money_fields, pages }) {
      this.money_fields = money_fields || []
      this.pages = pages
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        const sum = values.reduce((prev, curr) => prev + curr, 0);
        if (sum !== null && !(["product_title","artist_nickname"].includes(column.property))) sums[index] = numberFormat(sum, 2, '.', ',', 'round')
      });
      return sums;
    }
  },
  updated() {
    this.$nextTick(() => {
      //重新计算表格高度,合并列高度
      this.$refs.TABLE.doLayout();
    });
  }
}
</script>

<style scoped lang="scss">
.trade_stat_settle_container {
  font-family: FjallaOne, DINPro Medium, sans-serif !important;
  height: calc(100vh - 226px);
  padding: 20px;

  &__search {
    margin-top: 40px;
  }

  &__tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__platforms {
    color: #8a8e99;

    span {
      cursor: pointer;
      padding: 8px 20px;
    }

    span.active {
      color: #fe346e;
      font-weight: 700;
    }

    span:first-child {
      padding-left: 0 !important;
    }
  }

  .ml-tabs-box {
    display: inline-block;
    line-height: 0;
    position: relative;
    vertical-align: middle;
    min-width: 64px;
    --mx-tabs-box-shadow-gap: 2px;
    --input-h-gap: var(--btn-h-gap, 8px);
    --input-min-width: var(--btn-min-width, 48px);
    --input-font-size: var(--btn-font-size, 12px);
    border-radius: 500px;
    background-color: #f8f9fa;
    overflow: visible;

    .ml-tab.ml-tab_active {
      background-color: transparent;
      color: #ff3176;
    }

    .ml-tab:first-child {
      border-bottom-left-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
    }

    .ml-tab {
      min-width: 68px;
      align-items: center;
      background-color: transparent;
      color: var(--mx-tabs-box-color, #666);
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      justify-content: center;
      margin: 2px;
      padding: 0 var(--input-h-gap, 12px);
      position: relative;
      z-index: 3;
      height: 26px;
      transition: all 0.2s;
    }

    .ml-tab:hover {
      background-color: #fff4f8;
      border-radius: 500px;
      color: #333;
    }

    .ml-tab_active-bg {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08);
      background-color: #fff;
      border-radius: 500px;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      height: calc(100% - 4px);
      top: 2px;
      width: 84px;
    }
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  .ri::after, .lf::after {
    clear: both;
    display: block;
    content: '';
    height: 0;
    visibility: hidden;
  }

  .plat-wrap-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

    .account-plat-item {
      position: relative;
      height: 30px;
      line-height: 30px;
      color: #212133;
      font-weight: 600;
      font-size: 16px;
      padding: 0;
      margin: 0 0 0 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .account-plat-item:hover {
      color: #fe346e;
    }

    .account-plat-item-name {
      line-height: 20px;
      margin-left: 8px;
    }

    .ri::after, .lf::after {
      clear: both;
      display: block;
      content: '';
      height: 0;
      visibility: hidden;
    }

    .account-plat-item.active::after {
      content: "";
      display: inline-block;
      width: 28px;
      height: 2px;
      background: #FF405C;
      position: absolute;
      left: 50%;
      margin-left: -14px;
      visibility: inherit;
      top: auto;
      bottom: -6px;
    }
  }
}

</style>
